import { invariant } from "@epic-web/invariant";
import _ from "lodash-es";

export const tints = { 50: 0.95, 100: 0.9, 200: 0.75, 300: 0.6, 400: 0.3 };

export const shades = { 600: 0.9, 700: 0.6, 800: 0.45, 900: 0.3 };

export function hexPart(c) {
	return `0${c.toString(16)}`.slice(-2);
}

export function hexToRgb(hex) {
	const color = `${hex}`,
		components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
	if (!components) {
		return null;
	}
	return {
		r: parseInt(components[1], 16),
		g: parseInt(components[2], 16),
		b: parseInt(components[3], 16),
	};
}
export function rgbToHex(r, g, b) {
	return `#${hexPart(r)}${hexPart(g)}${hexPart(b)}`;
}
export function tint(hex, intensity) {
	const color = hexToRgb(hex);
	invariant(color, "color should be defined");
	const r = Math.round(color.r + (255 - color.r) * intensity),
		g = Math.round(color.g + (255 - color.g) * intensity),
		b = Math.round(color.b + (255 - color.b) * intensity);
	return rgbToHex(r, g, b);
}

export function shade(hex, intensity) {
	const color = hexToRgb(hex),
		r = Math.round((color?.r || 0) * intensity),
		g = Math.round((color?.g || 0) * intensity),
		b = Math.round((color?.b || 0) * intensity);
	return rgbToHex(r, g, b);
}

export const brands = {
	base: "#084989",
	rv: "#228B22",
	honda: "#7f7f7f",
	kia: "#000000",
	volvo: "#000000",
	toyota: "#eb261d", // cb1f00
};

export const accents = {
	base: "#6EBB1F",
	rv: "#8B7343",
	honda: "#cc0000",
	toyota: "#252525",
	kia: "#000000",
	volvo: "#000000",
};

// https://javisperez.github.io/tailwindcolorshades
export const zdColors = {
	brandLight: _.mapValues(brands, (b) => tint(b, tints[300])),
	brand: _.mapValues(brands, (b) => b),
	brandDisabled: _.mapValues(brands, (b) => `${b}30`),
	brandDark: _.mapValues(brands, (b) => shade(b, shades[700])),

	accentLight: _.mapValues(accents, (b) => tint(b, tints[300])),
	accent: _.mapValues(accents, (b) => b),
	accentDisabled: _.mapValues(accents, (b) => `${b}30`),
	accentDark: _.mapValues(accents, (b) => shade(b, shades[700])),

	"zd-blue": {
		50: "#F3F6F9",
		100: "#E6EDF3",
		200: "#C1D2E2",
		300: "#9CB6D0",
		400: "#5280AC",
		500: "#084989",
		600: "#07427B",
		700: "#052C52",
		800: "#04213E",
		900: "#021629",
	},
	"zd-green": {
		50: "#F8FCF4",
		100: "#F1F8E9",
		200: "#DBEEC7",
		300: "#C5E4A5",
		400: "#9ACF62",
		500: "#6EBB1F",
		600: "#63A81C",
		700: "#427013",
		800: "#32540E",
		900: "#213809",
	},
	"zd-red": {
		50: "#FEF4F4",
		100: "#FDE9E9",
		200: "#FBC7C8",
		300: "#F8A6A7",
		400: "#F26366",
		500: "#ED2024",
		600: "#D51D20",
		700: "#8E1316",
		800: "#6B0E10",
		900: "#470A0B",
	},
	"zd-gray": {
		50: "#F6F6F7",
		100: "#EDEEEF",
		200: "#D3D4D7",
		300: "#B8BBBF",
		400: "#82878E",
		500: "#4D545E",
		600: "#454C55",
		700: "#2E3238",
		800: "#23262A",
		900: "#17191C",
	},
	// ...lightAndDarkColors("gray", "#656D78"),
	"zd-darkgray": {
		50: "#F6F6F6",
		100: "#ECEDEE",
		200: "#D0D2D4",
		300: "#B4B7BB",
		400: "#7B8087",
		500: "#434A54",
		600: "#3C434C",
		700: "#282C32",
		800: "#1E2126",
		900: "#141619",
	},
	"zd-lightgray": {
		50: "#FEFEFE",
		100: "#FDFDFD",
		200: "#F9FAFB",
		300: "#F5F6F8",
		400: "#EEF0F2",
		500: "#E6E9ED",
		600: "#CFD2D5",
		700: "#8A8C8E",
		800: "#68696B",
		900: "#454647",
	},
	"zd-lightestgray": {
		50: "#FFFFFF",
		100: "#FEFEFF",
		200: "#FDFDFE",
		300: "#FBFCFD",
		400: "#F8F9FC",
		500: "#F5F7FA",
		600: "#DDDEE1",
		700: "#939496",
		800: "#6E6F71",
		900: "#4A4A4B",
	},
	"zd-disabledgreen-500": "#6EBB1F30",
	"zd-disabledwhite-500": "#d3d3d3",
	"zd-black": {
		50: "#F3F3F3",
		100: "#E7E7E7",
		200: "#C3C4C4",
		300: "#9FA0A1",
		400: "#57585A",
		500: "#0F1113",
		600: "#0E0F11",
		700: "#090A0B",
		800: "#070809",
		900: "#050506",
	},
	"zd-darkgray25-500": "#434A5480",
	// red: "#077507",
	// red: "#084989",
	"zd-yellow": {
		50: "#FFFDF6",
		100: "#FEFBEC",
		200: "#FDF6D0",
		300: "#FBF0B4",
		400: "#F8E47C",
		500: "#F5D944",
		600: "#DDC33D",
		700: "#938229",
		800: "#6E621F",
		900: "#4A4114",
	},
};
