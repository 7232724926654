import type { ReactNode } from "react";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { accents, brands } from "~zipdeal-ui/tw/colors.ts";

export const BrandContext = createContext({
	brand: "base",
	setBrand: (e) => {},
	brandColor: brands.base,
	accentColor: accents.base,
	background: "",
	setBackground: (v) => {},
});

export const BrandProvider = ({
	children,
	defaultValue = "base",
}: {
	children?: ReactNode;
	defaultValue?: string;
}) => {
	const [brand, setBrand] = useState(defaultValue);
	const [brandColor, setBrandColor] = useState(brands[defaultValue]);
	const [accentColor, setAccentColor] = useState(accents[defaultValue]);

	const [background, setBackground] = useState("");

	useEffect(() => {
		setBrandColor(brands[brand]);
		setAccentColor(accents[brand]);
	}, [brand]);

	const context = {
		brand,
		setBrand,
		brandColor,
		accentColor,
		background,
		setBackground,
	};
	return (
		<BrandContext.Provider value={context}>{children}</BrandContext.Provider>
	);
};

export const useBrand = () => {
	const context = useContext(BrandContext);
	return context;
};
